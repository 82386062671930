import { ThemeContext } from "@emotion/react";
import React, { ReactNode } from "react";

class ThemeProvider extends React.Component<{children : ReactNode}, { name: string, changeTheme: () => void }> {
  state = {
    name: "",
    changeTheme: () => {}
  }

  changeTheme = () => {
    if(this.state.name === "dark") {
      this.setState({name: "light"})
      localStorage.setItem("upsun-theme", "light");
    } else {
      this.setState({name: "dark"})
      localStorage.setItem("upsun-theme", "dark");
    }
  }

  componentDidMount(): void {
    let preferredTheme = "dark";

    if(typeof window !==  `undefined`) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        preferredTheme = "dark";
      }

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        const newColorScheme = event.matches ? "dark" : "light";
        this.setState({name : newColorScheme})
      });
    }

    preferredTheme = localStorage.getItem("upsun-theme") || "dark";

    if(preferredTheme === "light") {
      this.setState({name: "light"})
    } else {
      this.setState({name: "dark"})
    }
  }
  
  render() {
    const { children } = this.props;
    const { name } = this.state;

    return(
      <ThemeContext.Provider value={{
        name,
        changeTheme: this.changeTheme
      }}>
        {name && children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext;
export { ThemeProvider }