import { GatsbyBrowser } from "gatsby";
import { ThemeProvider } from "./src/context/ThemeContext";
import React from "react";

// export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
//   <ThemeProvider>{element}</ThemeProvider>
// )

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
